@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

@layer utilities {
  .link-active {
    @apply rounded bg-primary-dark text-white;
  }

  .dropdown-menu:hover > i {
    @apply rotate-180;
  }

  .dropdown-menu:hover > ul {
    @apply max-h-96;
  }
}

/* Removes Text Input Outline */
input[type="text"]:focus {
  outline: none;
}

/* About us styles start */
.about-us {
  position: relative;
}

.about-us::before {
  position: absolute;
  content: "";
  width: 4.56rem;
  height: 4.56rem;
  background: #00a3fe;
  border-radius: 50%;
  top: -1rem;
  left: -1rem;
  z-index: -1;
}

.video-player {
  position: relative;
}

.video-player::before {
  position: absolute;
  content: "";
  width: 10rem;
  height: 10rem;
  background: #144b89;
  border-radius: 1.25rem;
  top: -1rem;
  left: -1rem;
  z-index: -1;
}

.video-player::after {
  position: absolute;
  content: "";
  width: 14.375rem;
  height: 14.375rem;
  background: #00ab6a;
  border-radius: 1.25rem;
  bottom: -1rem;
  right: -1rem;
  z-index: -1;
}

/* About us styles end */

/* Slider Styles Start */
#controls button:disabled,
#controls button:disabled {
  display: none;
}

.tns-nav {
  text-align: center;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  margin-bottom: 2rem;
  display: none;
}

.tns-nav button {
  height: 1rem;
  width: 1rem;
  background-color: #00a3fe;
  border-radius: 50%;
}
.tns-nav .tns-nav-active {
  background-color: #002347;
}
/* Slider Styles End */

@media only screen and (min-width: 320px) and (max-width: 479px) {
  * {
    font-size: 12px;
  }

  .tns-nav {
    display: flex;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  * {
    font-size: 12px;
  }
  .tns-nav {
    display: flex;
  }
}
